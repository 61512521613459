<template>
  <svg v-if="variant === 'light'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.21 244.11">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M161.92,0a13.16,13.16,0,0,0-8.49,3.09l-.05,0L49.55,107.6l18.63,18.73L169.69,24.21A13.38,13.38,0,0,0,161.92,0Z"
        />
        <g id="Rectangle_771_copy_3" data-name="Rectangle 771 copy 3">
          <polygon class="cls-1" points="142.67 164.03 124.13 182.67 152.51 211.2 171.13 192.47 142.67 164.03" />
        </g>
        <g id="Ellipse_770_copy_4" data-name="Ellipse 770 copy 4">
          <path
            class="cls-1"
            d="M143.63,164.91a13.13,13.13,0,0,0-18.64,0,13.32,13.32,0,0,0,0,18.75,13.12,13.12,0,0,0,18.64,0A13.32,13.32,0,0,0,143.63,164.91Z"
          />
        </g>
        <g id="Rectangle_771_copy" data-name="Rectangle 771 copy">
          <polygon class="cls-1" points="148.77 12.47 148.81 202.74 175.1 202.7 175.19 12.47 148.77 12.47" />
        </g>
        <path
          class="cls-1"
          d="M68.19,107.59a13.12,13.12,0,0,0-18.64,0,13.33,13.33,0,0,0,0,18.76,13.13,13.13,0,0,0,18.64,0A13.32,13.32,0,0,0,68.19,107.59Z"
        />
        <g id="Ellipse_770_copy_7" data-name="Ellipse 770 copy 7">
          <path class="cls-1" d="M162,188.69A13.28,13.28,0,1,0,175.14,202,13.22,13.22,0,0,0,162,188.69Z" />
        </g>
        <g id="Rectangle_771_copy_2" data-name="Rectangle 771 copy 2">
          <polygon class="cls-1" points="22.68 32.84 4.08 51.61 32.41 79.93 51.1 61.4 22.68 32.84" />
        </g>
        <g id="Ellipse_770_copy_3" data-name="Ellipse 770 copy 3">
          <path
            class="cls-1"
            d="M50.23,60.43a13.15,13.15,0,0,0-18.66,0,13.33,13.33,0,0,0,0,18.76,13.13,13.13,0,0,0,18.65,0A13.32,13.32,0,0,0,50.23,60.43Z"
          />
        </g>
        <g id="Rectangle_771" data-name="Rectangle 771">
          <polygon class="cls-1" points="26.39 41.34 0.09 41.34 0.03 231.62 26.43 231.64 26.39 41.34" />
        </g>
        <g id="Ellipse_770_copy" data-name="Ellipse 770 copy">
          <path
            class="cls-1"
            d="M107.09,117.76,5.52,219.9a13.38,13.38,0,0,0,7.77,24.21,13.17,13.17,0,0,0,8.5-3.09l.05,0L125.73,136.49Z"
          />
        </g>
        <g id="Ellipse_770_copy_2" data-name="Ellipse 770 copy 2">
          <path
            class="cls-1"
            d="M125.75,117.73a13.13,13.13,0,0,0-18.65,0,13.3,13.3,0,0,0,0,18.75,13.13,13.13,0,0,0,18.65,0A13.33,13.33,0,0,0,125.75,117.73Z"
          />
        </g>
        <g id="Ellipse_770" data-name="Ellipse 770">
          <path class="cls-1" d="M13.24,28.83A13.28,13.28,0,1,0,26.42,42.11,13.22,13.22,0,0,0,13.24,28.83Z" />
        </g>
      </g>
    </g>
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.21 244.11">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M161.92,0a13.16,13.16,0,0,0-8.49,3.09l-.05,0L49.55,107.6l18.63,18.73L169.69,24.21A13.38,13.38,0,0,0,161.92,0Z"
        />
        <g id="Rectangle_771_copy_3" data-name="Rectangle 771 copy 3">
          <polygon class="cls-1" points="142.67 164.03 124.13 182.67 152.51 211.2 171.13 192.47 142.67 164.03" />
        </g>
        <g id="Ellipse_770_copy_4" data-name="Ellipse 770 copy 4">
          <path
            class="cls-1"
            d="M143.63,164.91a13.13,13.13,0,0,0-18.64,0,13.32,13.32,0,0,0,0,18.75,13.12,13.12,0,0,0,18.64,0A13.32,13.32,0,0,0,143.63,164.91Z"
          />
        </g>
        <g id="Rectangle_771_copy" data-name="Rectangle 771 copy">
          <polygon class="cls-1" points="148.77 12.47 148.81 202.74 175.1 202.7 175.19 12.47 148.77 12.47" />
        </g>
        <path
          class="cls-1"
          d="M68.19,107.59a13.12,13.12,0,0,0-18.64,0,13.33,13.33,0,0,0,0,18.76,13.13,13.13,0,0,0,18.64,0A13.32,13.32,0,0,0,68.19,107.59Z"
        />
        <g id="Ellipse_770_copy_7" data-name="Ellipse 770 copy 7">
          <path class="cls-1" d="M162,188.69A13.28,13.28,0,1,0,175.14,202,13.22,13.22,0,0,0,162,188.69Z" />
        </g>
        <g id="Rectangle_771_copy_2" data-name="Rectangle 771 copy 2">
          <polygon class="cls-1" points="22.68 32.84 4.08 51.61 32.41 79.93 51.1 61.4 22.68 32.84" />
        </g>
        <g id="Ellipse_770_copy_3" data-name="Ellipse 770 copy 3">
          <path
            class="cls-1"
            d="M50.23,60.43a13.15,13.15,0,0,0-18.66,0,13.33,13.33,0,0,0,0,18.76,13.13,13.13,0,0,0,18.65,0A13.32,13.32,0,0,0,50.23,60.43Z"
          />
        </g>
        <g id="Rectangle_771" data-name="Rectangle 771">
          <polygon class="cls-1" points="26.39 41.34 0.09 41.34 0.03 231.62 26.43 231.64 26.39 41.34" />
        </g>
        <g id="Ellipse_770_copy" data-name="Ellipse 770 copy">
          <path
            class="cls-1"
            d="M107.09,117.76,5.52,219.9a13.38,13.38,0,0,0,7.77,24.21,13.17,13.17,0,0,0,8.5-3.09l.05,0L125.73,136.49Z"
          />
        </g>
        <g id="Ellipse_770_copy_2" data-name="Ellipse 770 copy 2">
          <path
            class="cls-1"
            d="M125.75,117.73a13.13,13.13,0,0,0-18.65,0,13.3,13.3,0,0,0,0,18.75,13.13,13.13,0,0,0,18.65,0A13.33,13.33,0,0,0,125.75,117.73Z"
          />
        </g>
        <g id="Ellipse_770" data-name="Ellipse 770">
          <path class="cls-1" d="M13.24,28.83A13.28,13.28,0,1,0,26.42,42.11,13.22,13.22,0,0,0,13.24,28.83Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  props: {
    variant: {
      type: String,
      default: 'dark', // dark, light
    },
  },
}

</script>
